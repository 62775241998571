@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
    font-family: poppins, sans-serif;
    background-color: var(--bg-color, #000);
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.card-bg {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

h1 {
    font-family: poppins, sans-serif;
    font-weight: bold;
    font-size: 1.2rem;
    color: #fff;
    margin-bottom: 1rem;
}

.profileTab {
    min-height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ddd;
    border-radius: 1.5em;
    text-decoration: none;
    text-align: center;
}

.profileTab h4 {
    font-family: poppins, sans-serif;
    font-weight: bold;
    font-size: 1rem;
    color: #000;
    margin: 0;
}
.profileTab h5 {
    font-family: poppins, sans-serif;
    font-weight: 600;
    font-size: 0.8rem;
    color: rgba(187, 187, 187, 0.9);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;
}

.bluredIconButton {
    padding: 20px;
    padding-left: 25px;
    padding-right: 25px;
    margin-right: 10px;
    backdrop-filter: blur(10px);
    width: fit-content;
    border-radius: 30px;
}

.bluredIconButton img {
    margin-right: 5px;
}

.bluredIconButton.mini {
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 5px;
    backdrop-filter: blur(10px);
    width: fit-content;
    border-radius: 30px;
}

.bluredIconButton.mini img {
    margin-right: 5px;
    width: 17px;
}

.bluredIconButton.nomargin img {
    margin-right: 0px;
}

.dropdown-item:focus,
.dropdown-item:hover {
    background-color: #282828 !important;
}

.truncate-2-lines {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@media (max-width: 1220px) {
    .hide-1220 {
        display: none !important;
    }
}

@media (max-width: 420px) {
    .hide-420 {
        display: none !important;
    }

    .mt-0-420 {
        margin-top: 0 !important;
    }
}

@media (max-width: 250px) {
    .hide-250 {
        display: none !important;
    }
}

@media (max-width: 300px) {
    .hide-300 {
        display: none !important;
    }
}

@media (max-width: 350px) {
    .hide-350 {
        display: none !important;
    }
}

@media (max-width: 380px) {
    .hide-380 {
        display: none !important;
    }

    .show-380-end {
        justify-content: flex-end !important;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.link-btn {
    text-decoration: none;
    color: inherit;
}

.link-btn:hover {
    text-decoration: none;
    color: inherit;
}

.fl-marge::first-line::after {
    content: "coucou";
}

.linewrap {
    color: #fff;
    position: relative;
    border-left: 20px solid #000;
    width: 50%;
    font-size: 32px;
    line-height: 38px;
}

.linewrap em {
    position: relative;
    font-style: normal;
    background: #000;
}

.linewrap span {
    position: relative;
    left: -20px;
    border-bottom: 1px solid #fff;
    border-top: 1px solid #fff;
}

.linewrap b {
    color: #fff;
    position: relative;
    padding: 0 10px 0 0;
    background: #000;
    left: 10px;
}

.modal-auto .modal-dialog {
    justify-content: center;
}

.modal-auto .modal-content {
    width: auto;
    max-width: 100%;
}

.modal-400 .modal-dialog {
    justify-content: center;
}

.modal-400 .modal-content {
    width: 100%;
    max-width: 400px;
}

.custom-scroll {
    overflow-y: scroll;
}

.custom-scroll::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    /* width of the entire scrollbar */
}

.black::-webkit-scrollbar-track {
    background: black !important;
}

.custom-scroll::-webkit-scrollbar-track {
    background: transparent;
    /* color of the tracking area */
}

.custom-scroll::-webkit-scrollbar-thumb {
    background-color: rgba(119, 119, 119, 0.624);
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
}

.hidden-scroll {
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
}

.hidden-scroll::-webkit-scrollbar {
    display: none;
    width: 0px;
    height: 0px;
    /* width of the entire scrollbar */
}

/* custom dropdown */
.dropdown-menu::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    /* width of the entire scrollbar */
}

.dropdown-menu::-webkit-scrollbar-track {
    background: transparent;
    /* color of the tracking area */
}

.dropdown-menu::-webkit-scrollbar-thumb {
    background-color: rgba(119, 119, 119, 0.85);
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
}

a.dropdown-item.active:hover,
a.dropdown-item.active:focus {
    background-color: #282828 !important;
}

a.dropdown-item.active {
    background-color: transparent !important;
}

[data-drop] {
    position: relative;
}

[data-drop] > .dropdown-menu {
    position: absolute !important;
    transform: translate(-50%, -50%) !important;
    height: fit-content;
    place-self: center;
    max-height: 64px !important;
    overflow-y: auto;
    top: 50% !important;
    left: 50% !important;
}

.h-blue:hover,
.h-blue:active,
.h-blue:focus,
.h-blue:visited {
    background-color: #80ffd7 !important;
    border-color: #80ffd7 !important;
    color: #000 !important;
}

.h-white:hover,
.h-white:active,
.h-white:focus,
.h-white:visited {
    background-color: #fff !important;
    border-color: #fff !important;
    color: #000 !important;
}

.nfd {
    backdrop-filter: none !important;
}

.custom-dropdown-item:hover {
    background-color: #ddd !important;
    color: #000;
}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Full viewport height */
    background-color: #000000;
    /* Light background color */
}

.loader {
    /* border: 8px solid #f3f3f3; */
    /* Light grey border */
    /* border-top: 8px solid #3498db; */
    /* Blue color for the top border */
    /* border-radius: 50%; */
    width: 10%;
    height: 10%;
    min-width: 60px;
    min-height: 60px;
    /* animation: spin 1s linear infinite; */
}

/* @keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
} */

.grecaptcha-badge {
    opacity: 0;
}

.dropdown-toggle-custom::after {
    display: none !important;
}

.modal-dialog:has(.new-modal) {
    max-width: min(95%, 625px) !important;
}

.modal-dialog:has(.lg) {
    max-width: min(95%, 1000px) !important;
}

.new-modal {
    background-color: #000;
    color: #fff;
    border-radius: 10px;
    /* border: 1px solid #fff; */
}

.new-modal pre {
    color: #000;
}

.new-modal svg {
    color: #fff;
}

.copy-code-btn.btn-primary,
.new-modal .btn-primary {
    padding: 12px 20px;
    background-color: #80ffd7;
    font-weight: 700;
    border-radius: 50px;
    border: 0;
    font-size: 14px;
    text-decoration: none;
    color: #000;
}

.copy-code-btn.btn-primary:active,
.copy-code-btn.btn-primary:hover,
.new-modal .btn-primary:active,
.new-modal .btn-primary:hover {
    background-color: #80ffd7;
    color: #000;
}

.new-modal .btn-secondary {
    padding: 12px 20px;
    background-color: var(--bs-btn-bg);
    font-weight: 700;
    border-radius: 50px;
    border: 0;
    font-size: 14px;
    text-decoration: none;
    color: #fff;
}

.new-modal .modal-footer,
.new-modal .modal-body,
.new-modal .modal-header {
    border-bottom-color: #6d6d6d;
    border-top-color: #6d6d6d;
}

.new-modal .modal-header .modal-title {
    font-size: 1.25rem;
    font-weight: bold;
    text-align: center;
    width: 100%;
}

.new-modal .modal-header .btn-close {
    position: absolute;
    top: 18px;
    right: 16px;
}

.modal-backdrop.show {
    opacity: var(--bs-backdrop-opacity);
    background-color: #252525;
}

.text-line2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.qcont:first-letter {
    text-transform: capitalize;
}

.indicator-circle {
    display: none;
    position: absolute;
    z-index: 1000;
    top: -10px;
    right: -5px;
    padding: 2px 8px;
    border-radius: 4px;
    /* animation: pulse 3s infinite; */
    transition: all 0.5s ease-in-out;
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    pointer-events: none;
}

.indicator-circle.rec-play {
    display: block;
    background-color: #f8665d;
    box-shadow: 0 0 4px 1px #f8665d;
}

.indicator-circle.rec-pause {
    display: block;
    background-color: #9e9e9e;
    box-shadow: 0 0 4px 1px #9e9e9e;
}

.indicator-circle.not-allowed {
    display: block;
    background-color: #ffea8f;
    box-shadow: 0 0 4px 1px #ffea8f;
    color: #000;
}

@keyframes pulse {
    0% {
        transform: scale(0.9);
        opacity: 0.8;
    }
    50% {
        transform: scale(1.1);
        opacity: 1;
    }
    100% {
        transform: scale(0.9);
        opacity: 0.8;
    }
}

.tip p {
    display: none;
}

.tip {
    position: relative;
}

.tip p {
    visibility: hidden;
    opacity: 0;
    width: max-content;
    background-color: var(--color-outer);
    border: 2px solid var(--color-outer);
    color: var(--color);
    text-align: center;
    border-radius: 6px;
    padding: 4px 8px;
    position: absolute;
    z-index: 1;
    bottom: 90%;
    animation: bounce 2s infinite;
    transition: opacity 0.5s ease-in-out;
    display: block;
}

.tip.hover:hover p,
.tip.active p {
    visibility: visible;
    opacity: 1;
}

/* Alignement par défaut (center) */
.tip p {
    --translate-x: -50%;
    --translate-y: -5px;
    left: 50%;
    transform: translateX(var(--translate-x)) translateY(var(--translate-y));
}

/* Alignement en bas */
.tip.down p {
    --translate-y: 5px;
    top: 140%;
    bottom: auto;
    transform: translateX(var(--translate-x)) translateY(var(--translate-y));
}

/* Alignement à gauche */
.tip.left p {
    --translate-x: 0;
    left: 0;
    transform: translateX(var(--translate-x)) translateY(var(--translate-y));
}

/* Alignement à droite */
.tip.right p {
    --translate-x: 0;
    right: 0;
    left: auto;
    transform: translateX(var(--translate-x)) translateY(var(--translate-y));
}

/* Flèche */
.tip p::after {
    content: "";
    position: absolute;
    top: calc(100% + 1px);
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: var(--color-outer) transparent transparent transparent;
}

/* Ajustement de la flèche pour alignement en bas */
.tip.down p::after {
    top: auto;
    bottom: calc(100% + 1px);
    border-color: transparent transparent var(--color-outer) transparent;
}

/* Ajustement de la flèche pour alignement gauche */
.tip.left p::after {
    left: 18px;
    margin-left: 0;
}

/* Ajustement de la flèche pour alignement droite */
.tip.right p::after {
    left: auto;
    right: 18px;
    margin-left: 0;
}

@keyframes bounce {
    0% {
        transform: translate(var(--translate-x), 0);
    }
    50% {
        transform: translate(var(--translate-x), var(--translate-y));
    }
    100% {
        transform: translate(var(--translate-x), 0);
    }
}

.recording-indicator {
    animation: pulse 2s infinite;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: #f8665d;
    box-shadow: 0 0 4px 1px #f8665d;
}

@keyframes pulse {
    0% {
        transform: scale(0.9);
        opacity: 0;
    }
    50% {
        transform: scale(1.1);
        opacity: 1;
    }
    100% {
        transform: scale(0.9);
        opacity: 0;
    }
}

.progress {
    transition: width 0.1s ease;
}

.phone-frame {
    box-shadow: rgba(255, 255, 255, 0.5) 0px 0px 30px 0px;
    width: 100%;
    max-width: 440px;
    aspect-ratio: 440 / 800;
    border: 6px solid rgb(235, 235, 235);
    border-radius: 3rem;
    margin-bottom: 30px;
    overflow: hidden;
}

.react-select-container .react-select__control {
    background-color: #404040;
    border-color: #a3a3a3;
    transition: none;
}
.react-select-container .react-select__control:hover {
    border-color: #a3a3a3;
}
.react-select-container .react-select__menu {
    background-color: #404040;
    border: 1px solid #a3a3a3;
}
.react-select-container .react-select__option {
    background-color: #404040;
}
.react-select-container .react-select__option:hover {
    background-color: #262626;
}
.react-select-container .react-select__indicator-separator {
    background-color: #a3a3a3;
}
.react-select-container .react-select__placeholder,
.react-select-container .react-select__single-value {
    color: #e3e3e3;
}

::-webkit-file-upload-button {
    cursor: pointer;
}

.animate-opacity {
    transition:
        opacity 0.5s ease,
        width 0.5s ease;
}

.appear-opacity {
    opacity: 1;
    animation: appear-opacity 0.5s ease;
}

@keyframes appear-opacity {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.btn-gray,
.btn-gray:hover {
    border-color: rgba(51, 51, 51, 0.8);
    background-color: rgba(51, 51, 51, 0.8);
    color: #fff;
}

.code {
    background-color: #eeeeee;
    font-family:
        Consolas,
        Monaco,
        Lucida Console,
        Liberation Mono,
        DejaVu Sans Mono,
        Bitstream Vera Sans Mono,
        Courier New;
}

/* make input not have outline */
.no-outline,
.no-outline:active,
.no-outline:focus {
    outline: none;
}

.no-list-style {
    list-style: none;
    padding: 0;
    margin: 0;
}

.w-95 {
    width: 95%;
}

.w-100 {
    width: 100%;
}

.btn-outline-light:hover {
    background-color: var(--bs-btn-hover-bg, #fff) !important;
    color: var(--bs-btn-hover-color, #000) !important;
}

.spinner-color {
    color: var(--bs-spinner-color, #fff) !important;
}

/* Texte défilant */
.message-defilant {
    display: block;
    /* margin: 40px auto; */
    padding: 0;
    overflow: hidden;
    position: relative;
    /* width: 50%; */
    /* max-width: 640px; */
    /* height: 29px; */
}

.message-defilant div {
    position: absolute;
    min-width: 100%; /* au minimum la largeur du conteneur */
}

.message-defilant div span,
.message-defilant div:after {
    position: relative;
    display: inline-block;
    white-space: nowrap;
    top: 0;
}

.message-defilant.slide div span {
    animation: defilement 16s infinite linear;
    /* background: #cde; */
}

.message-defilant.slide div:after {
    position: absolute;
    top: 0;
    left: 0;
    content: attr(data-text);
    animation: defilement2 16s infinite linear;
    /* background: #edc; */
}

@keyframes defilement {
    0% {
        margin-left: 0;
    }
    12.5% {
        margin-left: 0;
    }
    100% {
        margin-left: -100%;
    }
}

@keyframes defilement2 {
    0% {
        margin-left: 100%;
    }
    12.5% {
        margin-left: 100%;
    }
    100% {
        margin-left: 0%;
    }
}

/* Added by Thierry */
.nav-link,
.nav-link:hover {
    color: #ffffff;
    text-decoration: none;
}

/* Home tabs style */
.homeTabs {
    border: none;
}
.homeTabs .nav-link {
    background-color: transparent !important;
    border: none;
    font-weight: bold;
    color: #606060!important;
}
.homeTabs .nav-link.active {
    font-weight: bold;
    border-bottom: 1px solid #80ffd7;
    color: #fff!important;
}

.custom-switch .form-check-input {
    height: 1.5rem;
    width: 2.5rem;
    margin-top: 0.2rem;
}
.custom-switch .form-check-input:checked {
    background-color: #80fed8;
    border-color: #80fed8;
}
.custom-switch .form-check-input:checked:focus {
    background-color: #80fed8;
    border-color: #80fed8;
}
.custom-switch .form-check-input:focus {
    border-color: rgba(0, 0, 0, 0.25);
    box-shadow: none;
}
.form-switch .form-check-input:checked::after {
    background-color: #000;
    transform: translateX(1rem);
}
.form-switch .form-check-input::after {
    background-color: #fff;
}

.form-switch .form-check-input:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='black'/%3e%3c/svg%3e");
}
