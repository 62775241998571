.pagination-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.5rem;
    padding: 0rem;
}

.pagination-item {
    background-color: white;
    list-style-type: none;
    border-radius: 0.5rem;
    cursor: pointer;
    overflow: hidden;

    max-width: 2rem;
    width: 100%;
    aspect-ratio: 1;
}

.pagination-item > a,
.pagination-item > span {
    height: 100%;
    color: black;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pagination-item > a:hover,
.pagination-item > span:hover {
    background-color: #000000;
    color: white;
}

.item-selected > a,
.item-selected > span {
    background-color: #80fed8;
    color: black;
}

.table-content {
    border-collapse: collapse;
    width: 100%;
}

.table-content th,
.table-content td {
    padding-top: 10px;
    padding-bottom: 10px;
}

.table-content tr {
    border-bottom: 1px solid #000000;
    color: white;
}

.table-content tbody tr:last-child {
    border-bottom: none;
}

/* Conteneur principal de l'application */
.app-container {
    display: flex;
    height: 100vh;
}

/* Sidebar (Menu latéral) */
.sidebar {
    background-color: #000;
    color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: width 0.3s;
    width: 120px;
}

/* Bouton de basculement */
.toggle-button {
    background: none;
    border: none;
    color: #fff;
    padding: 10px;
    cursor: pointer;
    text-align: center;
}

/* Liste des éléments du menu */
.menu-items {
    padding: 10px;
}

.menu-item {
    margin-bottom: 10px;
    list-style: none;
}

/* Conteneur principal */
.main-container {
    flex: 1;
    background-color: #f4f4f4;
    padding: 20px;
    overflow: auto;
}

/* Menu en mode réduit */
.sidebar.collapsed {
    width: 60px;
}

/* Conteneur principal du tableau */
.table-admin {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: white;
    background-color: black;
}

/* En-tête du tableau */
.table-header {
    display: flex;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
    padding: 8px 0;
    font-weight: bold;
}

/* Ligne du tableau */
.table-row {
    display: flex;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    align-items: center;
}

.table-row:last-child {
    border-bottom: none; /* Pas de bordure pour la dernière ligne */
}

/* Cellule de tableau */
.table-cell {
    padding: 4px;
    text-align: center;
}

.table-cell:last-child {
    border-right: none; /* Pas de bordure pour la dernière cellule */
}

/* Largeurs personnalisées des colonnes */
.colo-1 {
    flex: 0 1 5%;
}
.colo-2 {
    flex: 0 1 20%;
}
.colo-3 {
    flex: 1 1 30%;
}
.colo-4 {
    flex: 1 1 40%;
}
.colo-6 {
    flex: 1 1 60%;
}

.ellipsis {
    display: -webkit-box; /* Boîte flexible pour prendre en charge le nombre de lignes */
    -webkit-line-clamp: 2; /* Nombre maximum de lignes */
    -webkit-box-orient: vertical; /* Orientation verticale */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    padding: 4px;
    position: relative; /* Nécessaire pour positionner l'infobulle */
    margin-right: 4px;
    text-align: left;
}

.ellipsis:hover::after {
    content: attr(title); /* Afficher le contenu complet */
    position: absolute;
    top: 100%; /* Juste en dessous de l'élément */
    left: 0;
    width: max-content;
    max-width: 400px; /* Limite la largeur de l'infobulle */
    background-color: #aef2c1;
    color: #fff;
    padding: 4px;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(138, 211, 155, 0.1);
    z-index: 10;
    white-space: normal;
    word-wrap: break-word; /* Permet le retour à la ligne si nécessaire */
}

.ellipsis:hover {
    cursor: pointer;
}

.imageGallery {
    border-radius: 25%;
    object-fit: cover;
    border: 1px solid #ccc;
}
.remImg2 {
    width: 2rem;
    height: 2em;
}
.remImg3 {
    width: 3rem;
    height: 3rem;
}
.buttonAudio {
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
}

.image-container {
    /*display: inline-block; /* Permet à l'image d'être positionnée dans un conteneur */
    overflow: hidden; /* Cache tout excédent d'image pendant l'agrandissement */
}

.image {
    /* La taille de l'image prendra toute la largeur de son conteneur */
    transition: transform 0.3s ease; /* Animation fluide pour l'agrandissement */
}

.image-container:hover .image {
    transform: scale(2); /* Agrandit l'image à 200% de sa taille originale au survol */
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
    body {
        font-size: 16px;
    }
}
